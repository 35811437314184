import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 1000px;
`;

export default function Custom404() {
  return (
    <Wrapper>
      <h1>페이지가 존재하지 않습니다.</h1>
    </Wrapper>
  );
}
